// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-en-jsx": () => import("./../../../src/pages/404.en.jsx" /* webpackChunkName: "component---src-pages-404-en-jsx" */),
  "component---src-pages-404-es-jsx": () => import("./../../../src/pages/404.es.jsx" /* webpackChunkName: "component---src-pages-404-es-jsx" */),
  "component---src-pages-academy-en-jsx": () => import("./../../../src/pages/academy.en.jsx" /* webpackChunkName: "component---src-pages-academy-en-jsx" */),
  "component---src-pages-academy-es-jsx": () => import("./../../../src/pages/academy.es.jsx" /* webpackChunkName: "component---src-pages-academy-es-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../../../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-es-jsx": () => import("./../../../src/pages/index.es.jsx" /* webpackChunkName: "component---src-pages-index-es-jsx" */),
  "component---src-pages-services-en-jsx": () => import("./../../../src/pages/services.en.jsx" /* webpackChunkName: "component---src-pages-services-en-jsx" */),
  "component---src-pages-servicios-es-jsx": () => import("./../../../src/pages/servicios.es.jsx" /* webpackChunkName: "component---src-pages-servicios-es-jsx" */),
  "component---src-templates-curso-detail-jsx": () => import("./../../../src/templates/curso-detail.jsx" /* webpackChunkName: "component---src-templates-curso-detail-jsx" */)
}

